import ko from 'knockout';
import moment from 'moment';
import kx from '@/gr/common/knockout/extended';
import { IWidgetHostMediator, ITimeContext } from './interfaces/widgetHostMediator';

const modifiedWindow = window as ModifiedWindow & typeof globalThis;

export class widgetHostMediator {
  public static get = (): WidgetHostMediator | null => {
    const host = modifiedWindow.grHostState;
    if (!host) return null;

    return new WidgetHostMediator(host);
  };
}

class WidgetHostMediator implements IWidgetHostMediator {
  timeContext: kx.ReadOnlyObservable<ITimeContext>;
  _timeContext: KnockoutObservable<ITimeContext>;

  constructor(private _host: IHostState) {
    this.timeContext = ko.observable<ITimeContext>({ dateTime: moment(), isRealtime: true });
    this._timeContext = this.timeContext;

    this._host.getTimeContext().then(([dateTime, isRealtime]) => {
      this._timeContext({ dateTime: moment.parseZone(dateTime), isRealtime });
    });

    modifiedWindow.grContentState = {
      setTimeContext: (timeContext: IApplicationTimeContext) => {
        this._timeContext({ dateTime: moment.parseZone(timeContext.dateTime), isRealtime: timeContext.isRealtime });
      }
    };
  }

  setTimeContextToRealtime() {
    this._host.setTimeContextToRealtime();
  }

  setTimeContextToTime(time: moment.Moment) {
    this._host.setTimeContextToTime(time.toISOString());
  }

  openUrl(url: string) {
    this._host.openUrl(url);
  }

  setTitle(title: string) {
    document.title = title;
    this._host.setTitle(title);
  }
}

interface IHostState {
  getTimeContext(): Promise<ApplicationTimeContextTuple>;

  setTimeContextToTime(isoTime: string): Promise<void>;
  setTimeContextToRealtime(): Promise<void>;
  openUrl(url: string): Promise<void>;
  setTitle(title: string): Promise<void>;
  setHelpUrl(helpUrl: string): Promise<void>;
}

interface IContentState {
  setTimeContext(timeContext: IApplicationTimeContext): void;
}

type ApplicationTimeContextTuple = [/* dateTime */ string, /* isRealtime */ boolean];

interface IApplicationTimeContext {
  dateTime: string;
  isRealtime: boolean;
}

interface ModifiedWindow extends Window {
  grHostState: IHostState;
  grContentState: IContentState;
}
