import ko from 'knockout';
import _ from 'lodash';
import kx from '@/gr/common/knockout/extended';
import { WidgetHostMediator } from './widgetHostMediator/widgetHostMediator';
import { SerialDisposable } from '@/gr/common/disposable';

export class TitleSetter {
  static instance = new TitleSetter();

  private _default = 'Time Series Viewer';
  private _disposable = new SerialDisposable();

  setTitle(title: kx.ReadOnlyObservable<string>): void {
    this._disposable.setDisposable(
      ko.computed(() => {
        this._setTitle(title());
      })
    );
  }

  private _setTitle(title: string) {
    if (_.isNil(title) || _.isEmpty(title.trim())) title = this._default;

    const host = WidgetHostMediator.get();
    if (host) {
      host.setTitle(title);
    }
    document.title = title;
  }

  dispose(): void {
    this._disposable.dispose();
  }
}
