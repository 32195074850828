import * as v1 from './v1WidgetHostMediator';
import * as v2 from './v2WidgetHostMediator';
import * as v3 from './v3WidgetHostMediator';
import { ITimeContext, IWidgetHostMediator } from './interfaces/widgetHostMediator';
import kx from '@/gr/common/knockout/extended';
import ko from 'knockout';
import moment from 'moment';
export * from './interfaces/widgetHostMediator';

export class WidgetHostMediator {
  static instance: IWidgetHostMediator;

  public static get = (): IWidgetHostMediator | null => {
    if (WidgetHostMediator.instance) return WidgetHostMediator.instance;

    const v3State = v3.widgetHostMediator.get();
    if (v3State) {
      WidgetHostMediator.instance = v3State;
      return WidgetHostMediator.instance;
    }

    const v2State = v2.widgetHostMediator.get();
    if (v2State) {
      WidgetHostMediator.instance = v2State;
      return WidgetHostMediator.instance;
    }

    const v1State = v1.widgetHostMediator.get();
    if (v1State) {
      WidgetHostMediator.instance = v1State;
      return WidgetHostMediator.instance;
    }

    WidgetHostMediator.instance = new DefaultWidgetHostMediator();
    return WidgetHostMediator.instance;
  };
}

class DefaultWidgetHostMediator implements IWidgetHostMediator {
  timeContext: kx.ReadOnlyObservable<ITimeContext>;
  _timeContext: KnockoutObservable<ITimeContext>;

  constructor() {
    this.timeContext = ko.observable<ITimeContext>({ dateTime: moment(), isRealtime: true });
    this._timeContext = this.timeContext;
  }

  setTimeContextToRealtime() {
    const now = moment();
    this._timeContext({ dateTime: now, isRealtime: true });
  }

  setTimeContextToTime(time: moment.Moment) {
    this._timeContext({ dateTime: time, isRealtime: false });
  }

  openUrl() {
    // empty
  }

  setTitle(title: string) {
    document.title = title;
  }
}
