
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { SwaggerException, TrendEntryPointArgs } from '@/repositories';
import ko from 'knockout';
import { TrendKOC } from '@/apps/timeSeriesViewer/components/trendComponent';
import { GrLoading, GrTrendPropertiesBar, GrTrendsAppBar } from '@/components';

@Component({
  components: { GrTrendPropertiesBar, GrTrendsAppBar, GrLoading }
})
export default class TrendEditor extends Vue {
  @Prop()
  id!: string;

  @Getter('trendStore/trendEntryPointArgs') trendEntryPointArgs!: TrendEntryPointArgs;
  @Mutation('trendStore/loadTrendEntryPointArgs') setTrendEntryPointArgs!: (args: TrendEntryPointArgs) => void;
  @Action('trendStore/loadTrendEntryPointArgs') loadTrendEntryPointArgs!: (id: string) => Promise<void>;

  private loading = true;

  // private navDrawer = false;

  private get isLoading() {
    return this.loading;
  }

  async created(): Promise<void> {
    await this.load();
  }

  @Watch('id')
  private async load() {
    this.loading = true;
    try {
      await this.loadTrendEntryPointArgs(this.id);
    } catch (e) {
      if (e instanceof SwaggerException) {
        window.alert(`Unable to load\n\n${e.response}`);
        window.location.assign('/');
        return;
      }
    }
    this.loading = false;
    const args = await new TrendKOC.ArgsFactory().create(this.trendEntryPointArgs, this);
    ko.cleanNode(document); // for reloading in dev or when id changes on the fly
    ko.applyBindings(args);
  }

  private get showAppBar() {
    return this.trendEntryPointArgs.showAppBar;
  }
}
