import ko from 'knockout';
import moment from 'moment';
import kx from '@/gr/common/knockout/extended';
import { IWidgetHostMediator, ITimeContext } from './interfaces/widgetHostMediator';

const modifiedWindow = window as ModifiedWindow & typeof globalThis;

export class widgetHostMediator {
  public static get = (): WidgetHostMediator | null => {
    const hostState = modifiedWindow.globalroamSharedState;
    if (!hostState) return null;

    return new WidgetHostMediator(hostState);
  };
}

class WidgetHostMediator implements IWidgetHostMediator {
  applicationId!: string;
  applicationDisplayName!: string;

  timeContext: kx.ReadOnlyObservable<ITimeContext>;
  _timeContext: KnockoutObservable<ITimeContext>;

  constructor(private _hostState: IHostState) {
    const timeContext =
      _hostState.currentPointInTime === undefined ? { dateTime: moment(), isRealtime: true } : { dateTime: moment(_hostState.currentPointInTime), isRealtime: _hostState.isRealTimeMode };
    this.timeContext = this._timeContext = ko.observable(timeContext);

    modifiedWindow.globalroam = {
      currentPointInTime: (pointInTime: moment.MomentInput) => {
        this._timeContext({ dateTime: moment(pointInTime), isRealtime: this._timeContext().isRealtime });
      },
      isRealTimeMode: (isRealtime: boolean) => {
        this._timeContext({ dateTime: this._timeContext().dateTime, isRealtime: isRealtime });
      }
    };
  }

  setTimeContextToRealtime() {
    throw new Error('Operation not supported');
  }

  setTimeContextToTime(time: moment.Moment) {
    this._hostState.jumpToTime(time.toISOString());
  }

  openUrl() {
    throw new Error('Operation not supported');
  }

  setTitle() {
    // do nothing
  }
}

interface IHostState {
  isRealTimeMode: boolean;
  currentPointInTime: string;

  jumpToTime(isoTime: string): void;
}

interface IWidgetState {
  isRealTimeMode: (value: boolean) => void;
  currentPointInTime: (value: string) => void;
}

interface ModifiedWindow extends Window {
  globalroamSharedState: IHostState;
  globalroam: IWidgetState;
}
