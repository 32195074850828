import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import moment from 'moment';
import { IWidgetHostMediator, ITimeContext } from './interfaces/widgetHostMediator';

const modifiedWindow = window as ModifiedWindow & typeof globalThis;

export class widgetHostMediator {
  public static get = (): WidgetHostMediator | null => {
    const state = modifiedWindow.grSharedState;
    if (!state || !state.version || !state.host || !state.widget) return null;

    return new WidgetHostMediator(state);
  };
}

class WidgetHostMediator implements IWidgetHostMediator {
  timeContext: kx.ReadOnlyObservable<ITimeContext>;
  _timeContext: KnockoutObservable<ITimeContext>;

  constructor(private _state: ISharedState) {
    this.timeContext = this._timeContext = ko.observable({ dateTime: moment(_state.host.timeContext.dateTime), isRealtime: _state.host.timeContext.isRealtime });
    _state.widget.setTimeContext = (timeContext) => {
      this._timeContext({ dateTime: moment(timeContext.dateTime), isRealtime: timeContext.isRealtime });
    };
  }

  setTimeContextToRealtime() {
    this._state.host.setTimeContextToRealtime();
  }

  setTimeContextToTime(time: moment.Moment) {
    this._state.host.setTimeContextToTime(time.toISOString());
  }

  openUrl(url: string) {
    this._state.host.openUrl(url);
  }

  setTitle(title: string) {
    document.title = title;
    this._state.host.setTitle(title);
  }
}

interface IApplicationState {
  timeContext: IApplicationTimeContext;

  setTimeContextToTime(isoTime: string): void;
  setTimeContextToRealtime(): void;
  openUrl(url: string): void;
  setTitle(title: string): void;
}

interface IWidgetState {
  setTimeContext(timeContext: IApplicationTimeContext): void;
}

interface IApplicationTimeContext {
  dateTime: string;
  isRealtime: boolean;
}

interface ISharedState {
  version: string;
  host: IApplicationState;
  widget: IWidgetState;
}

interface ModifiedWindow extends Window {
  grSharedState: ISharedState;
}
