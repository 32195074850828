import _ from 'lodash';
import ko from 'knockout';
import * as log from '@/gr/common/log';
import { CompositeDisposable } from '@/gr/common/disposable';
import { Result } from '@/gr/common/result';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { TitleSetter } from '@/common/titleSetter';
import {
  ErrorHelper,
  analytics,
  NewVersionDialogArgs,
  TrendServices,
  Trend,
  untitledTrendName,
  IPolicyAuthorisationDto,
  IProductDto,
  ITrendResourceDto,
  TrendStepId,
  TrendPropertiesBar,
  InputDefinitionChooser,
  ConfigureDataDefinitionsPage,
  OutputChannels,
  PublishComponent
} from '@/apps/timeSeriesViewer';
import { TrendEntryPointArgs } from '@/repositories';
import Vue from 'vue';

export interface ITrendEntryPointArgs {
  trendResource: ITrendResourceDto;
  product: IProductDto;
  apiVersion: string;
  policyAuthorisations: IPolicyAuthorisationDto[];
}

export class Component {
  private readonly _authentication;
  private readonly _disposable = new CompositeDisposable();
  private readonly _timeControl;
  private readonly _trend = new Trend();
  private readonly _services;
  private _pages!: IPage[];

  readonly isLoadingApp = ko.observable(true);
  readonly isLoadingPart = this._trend.isLoadingPart;
  readonly trendPropertiesBar: TrendPropertiesBar.Args;
  readonly isApplicationBarVisible = ko.pureComputed(() => !this._services.policyAuthorisations.isAuthorised('Ez2ViewDesktopLicence')());
  readonly currentPage = ko.pureComputed(() => _.find(this._pages, (x) => x.stepId === this._trend.currentStepId()));
  readonly messages = this._trend.messages;
  readonly modalError = { html: ko.observable<string>() };

  readonly newVersionDialog = new NewVersionDialogArgs();

  constructor(private _args: Args) {
    this._authentication = this._args.vue.$authentication;
    this._timeControl = TimeControl.get(this._args.vue.$router);
    this._services = new TrendServices(
      this._trend,
      this._args.entryPointArgs.apiVersion,
      this._timeControl,
      () => this.newVersionDialog.showDialog(),
      this._args.entryPointArgs.policyAuthorisations,
      this._authentication
    );
    this._trend.productName = this._args.entryPointArgs.product.name;
    this._trend.prereleaseTag = this._args.entryPointArgs.product.prereleaseTag;
    this._trend.appVersion = this._args.entryPointArgs.apiVersion;

    ErrorHelper.setErrorHandler({ showModal: (html) => this.modalError.html(html) });

    this.trendPropertiesBar = new TrendPropertiesBar.ArgsFactory(this._trend, this._services).create();

    this.loadState();

    this._disposable.add(
      ko.computed(() => {
        analytics.stepStarted(this._trend.currentStepId());
      })
    );

    this._disposable.add(this._services);
  }

  dispose(): void {
    this._disposable.dispose();
  }

  private async loadState() {
    try {
      const [trendResult] = await Promise.all([this._services.trendsRepository.loadDtoInto(this._trend, this._args.entryPointArgs.trendResource)]);
      if (trendResult.isSuccess) {
        TitleSetter.instance.setTitle(ko.pureComputed(() => `${this._trend.title() || untitledTrendName} - ${this._args.entryPointArgs.product.name}`));
        this.initializePages();
        this.isLoadingApp(false);
        this._services.trendSaver.startAutoSaving();
      } else {
        ErrorHelper.showModal(trendResult.error);
        log.error('Unable to load the state for the application: {@error}', trendResult.error);
        return Result.error();
      }
    } catch (error) {
      ErrorHelper.showModal('Failed to load the application.');
      log.errorEx(error, 'GetTrend failed');
    }
  }

  private initializePages() {
    this._pages = [
      {
        stepId: 'RawDataSelection',
        dataTestName: 'raw-data-definition-chooser',
        component: {
          name: 'inputDataDefinitionChooser',
          params: new InputDefinitionChooser.ArgsFactory(this._trend, this._services).create()
        }
      },
      {
        stepId: 'ConfigureData',
        dataTestName: 'configure-data-definitions',
        component: {
          name: 'configureDataDefinitionsPage',
          params: new ConfigureDataDefinitionsPage.ArgsFactory(this._trend, this._services).create()
        }
      },
      {
        stepId: 'Viewer',
        dataTestName: 'output-channels',
        component: {
          name: 'outputChannels',
          params: new OutputChannels.ArgsFactory(this._trend, this._services).create()
        }
      },
      {
        stepId: 'Publish',
        dataTestName: 'publish',
        component: { name: 'publish', params: new PublishComponent.ArgsFactory(this._trend, this._services).create() }
      }
    ];
  }
}

interface IPage {
  stepId: TrendStepId;
  dataTestName: string;
  component: {
    name: string;
    params: InputDefinitionChooser.Args | ConfigureDataDefinitionsPage.Args | OutputChannels.Args | PublishComponent.Args;
  };
}

export class Args {
  constructor(
    public entryPointArgs: ITrendEntryPointArgs,
    public vue: Vue
  ) {}
}

export class ArgsFactory {
  async create(args: TrendEntryPointArgs, vue: Vue): Promise<Args> {
    return new Args(args as unknown as ITrendEntryPointArgs, vue);
  }
}

import html from './trendComponent.html';
import { TimeControl } from '@/common/timeContext';

defineComponent(() => Component, 'trendComponent', html);
require('./trendComponent.less');
